.dashboard-survey-row {
  padding: 1rem;
}

.sent-survey-row {
  cursor: pointer;
}

.sent-survey-row:hover {
  background-color: #dedede
}

.dashboard-medium-box {
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem 0;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
}

.enterprise-data:hover{
  background-color: #ebebeb;
}

.dashboard-main {
  margin: 0 !important;
  width: 100%;
}
p {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  color: #404040;
}

a:hover {
  text-decoration: none !important;
  color: #404040;
  opacity: 0.8;
}

.box-header {
  min-height: 2.5em;
  padding: 0 0 0 1rem;
  border-bottom: 1px solid #b5e413;
}

.bigger-text {
  font-size: 1.2rem;
  font-weight: 600;
}

.small-white-button {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  border: none;
  padding: 0.35rem;
  min-width: 6.5rem;
}

.small-white-button:hover {
  opacity: 0.7;
}

.custom-scrollbars > div {
  overflow-x: hidden !important;
  padding-bottom: 1rem;
}

.search-bar-input {
	outline: none;
}

.search-bar-input[type=search] {
	-webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

.search-bar-input::-webkit-search-decoration,
.search-bar-input::-webkit-search-cancel-button {
        display: none; 
}

.search-bar-input[type=search] {
	background: #fff url(./assets/search-icon.png) no-repeat 9px center;
    border: solid #fff;
    border-width: 0px 0px 1px 0px;
	padding: 0px 10px 0px 32px;
    width: 55px;
	
	/* -webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px; */
	
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}

.search-bar-input[type=search]:focus {
	width: 130px;
    background-color: #fff;
    border: solid ;
    border-width: 0px 0px 1px 0px;
  border-color: #000;
  
	
	/* -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
	-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
	box-shadow: 0 0 5px rgba(109,207,246,.5); */
}

.search-bar-input:focus::-webkit-input-placeholder { color:#999 !important; }
.search-bar-input:focus::-moz-placeholder  { color:#999 !important; }/* FF 4-18 */
.search-bar-input:focus::-moz-placeholder { color:#999 !important; } /* FF 19+ */
.search-bar-input:focus::-ms-input-placeholder { color:#999 !important; } /* IE 10+ */

/* .search-bar-input:-moz-placeholder {
	color: #999;
}

.search-bar-input::-webkit-input-placeholder {
	color: #999;
} */

#search-bar input[type=search] {
	width: 15px;
	padding-left: 10px;
	color: transparent;
	cursor: pointer;
}

#search-bar input[type=search]:hover {
	background-color: #fff;
}

#search-bar input[type=search]:focus {
	width: 150px;
	padding-left: 32px;
	color: #000;
	background-color: #fff;
	cursor: auto;
}

#search-bar input:-moz-placeholder {
	color: transparent;
}

#search-bar input::-webkit-input-placeholder {
	color: transparent;
}

main {
  padding-top: 70px;
  min-height: 100%;
  height: 100%;
}

.odd-row {
  padding: 0.5rem;
  /* padding-left: 2rem; */
}

.even-row {
  background-color: #F5F5F5;
  padding: 0.5rem;
  /* padding-left: 2rem; */
}

.delete:hover {
  background-color: red;
}

.enterprise-box {
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem 0;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
}

.custom-scrollbars > div {
  overflow-x: hidden !important;
  padding-bottom: 1rem;
}


.enterprise-edit {
  padding-right: 1em;
  padding-left: 1em;
}

.enterprise-edit .row {
  padding-top: 1em;
  padding-bottom: 1em;
}

.enterprise-edit .row:nth-child(odd) {
  background-color: #F5F5F5;
}

.logo-container {
  position: relative;
  height: 100px;
  width: 100px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.small-white-button {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  background-color: #fff;
  border: none;
  padding: 0.35rem;
  color: rgb(47, 47, 47);
  min-width: 6.5rem;
}

.small-white-button:hover {
  opacity: 0.7;
}

.small-white-button.selected {
  background-color: #B5E413;
  color: #FFF;
}

.small-white-button.lighter-text.selected {
  font-weight: 400;
}