html, body, #root {
  min-height: 100%;
  height: 100%;
}

body {
  background-image: url('/images/background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Raleway', sans-serif !important;
  background-attachment: fixed;
}

.App {
  overflow: auto;
}
.dashboard-survey-row {
  padding: 1rem;
}

.sent-survey-row {
  cursor: pointer;
}

.sent-survey-row:hover {
  background-color: #dedede
}

.dashboard-medium-box {
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem 0;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
}

.enterprise-data:hover{
  background-color: #ebebeb;
}

.dashboard-main {
  margin: 0 !important;
  width: 100%;
}
p {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  color: #404040;
}

a:hover {
  text-decoration: none !important;
  color: #404040;
  opacity: 0.8;
}

.box-header {
  min-height: 2.5em;
  padding: 0 0 0 1rem;
  border-bottom: 1px solid #b5e413;
}

.bigger-text {
  font-size: 1.2rem;
  font-weight: 600;
}

.small-white-button {
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  border: none;
  padding: 0.35rem;
  min-width: 6.5rem;
}

.small-white-button:hover {
  opacity: 0.7;
}

.custom-scrollbars > div {
  overflow-x: hidden !important;
  padding-bottom: 1rem;
}

.search-bar-input {
	outline: none;
}

.search-bar-input[type=search] {
	-webkit-appearance: textfield;
  box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

.search-bar-input::-webkit-search-decoration,
.search-bar-input::-webkit-search-cancel-button {
        display: none; 
}

.search-bar-input[type=search] {
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAvRJREFUeNqUVE9Ik2EYf/fv29z//4vPqWnbUKcijaWHYCIEXrzEQA8SeZkE6+AONoKEGIG3Ljukp1FUt7ROghR4CTILjRoeuuRSdIaubbi26dbvGU6+dAN74eH7tvd5f8/v/f2e5xOVy2VWXTabjQmXSCRiEonEI5PJbkul0mv47SiVSmtHR0crxWIxenx8vHWaWw8IAJxcLp/WaDRhlUolMRgMGURhd3dXk8lkuGw2m0bcyefzLwhDymosgDCtVvtEp9ONd3R07IfD4Y/t7e0/sJVC8EtLS5ej0ejVRCLx/ODggMvlcjFGaNWwWq0VVq2trYN9fX3liYmJZKFQmMEe73K5mMPhYACk3F6APBseHs57vd5sc3OzUXyWDbRgTU1N98CoHAwGX0Kf+3a7fTuVSrF0Os3AgOHgGv67Ozo6+kGtVqtQ/NY5IBxkYrH4OsD2u7q6HjU2NpYg7Ok+MYcurKWlJTUwMBDhOK6Em9ysCQR3lNDpl9PpTMKhWjIyXJnxPL+M/GJDQwN/Dogsh8i5vb09Daxm9RbtQS81hObg5h9xrQSI/ROa8GDVTcD1nAXAONV2u93r54DoKkNDQ69AuazX659CfHUt1tCmE8+HxN7v98fEZxMQgUgkcsPj8STQiL2oGkdj+mGA+oTJJYVCETIajZ+wrwwEAm9hyPI/nd3W1hbAwVlUYWCT9vl8W4uLi1egFzUdw0j8BkMdLCfX0qFQaAX9NgmHv0qFICaTadZisTBUpCtqNzY2Ps/NzX2bn5/vjMfj5sPDQwmYbPf09CRHRkbeg9FjAH6n9qgwqoJQZ1M1uiIq59CQM/39/VHUGUS4EUrEPmId8Q5MCgRCGCIgdpvN5i80GgQCLYhRbmpq6jXmbBJdvHPyFajskatkCAEI20OysLBg2tzcHEOigpKFIKi4Q41XPUjv9IRWTKhtBSgWi2UhGL+6uuqkNgLIGyHIRVfVNS2qTmOGdBDwwf+CnP2wUU9JMdUF4ZBedP0VYACy1Fw50QDW/AAAAABJRU5ErkJggg==) no-repeat 9px center;
    border: solid #fff;
    border-width: 0px 0px 1px 0px;
	padding: 0px 10px 0px 32px;
    width: 55px;
	
	/* -webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px; */
	transition: all .5s;
}

.search-bar-input[type=search]:focus {
	width: 130px;
    background-color: #fff;
    border: solid ;
    border-width: 0px 0px 1px 0px;
  border-color: #000;
  
	
	/* -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
	-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
	box-shadow: 0 0 5px rgba(109,207,246,.5); */
}

.search-bar-input:focus::-webkit-input-placeholder { color:#999 !important; }
.search-bar-input:focus::-moz-placeholder  { color:#999 !important; }/* FF 4-18 */
.search-bar-input:focus::-moz-placeholder { color:#999 !important; } /* FF 19+ */
.search-bar-input:focus::-ms-input-placeholder { color:#999 !important; } /* IE 10+ */

/* .search-bar-input:-moz-placeholder {
	color: #999;
}

.search-bar-input::-webkit-input-placeholder {
	color: #999;
} */

#search-bar input[type=search] {
	width: 15px;
	padding-left: 10px;
	color: transparent;
	cursor: pointer;
}

#search-bar input[type=search]:hover {
	background-color: #fff;
}

#search-bar input[type=search]:focus {
	width: 150px;
	padding-left: 32px;
	color: #000;
	background-color: #fff;
	cursor: auto;
}

#search-bar input:-moz-placeholder {
	color: transparent;
}

#search-bar input::-webkit-input-placeholder {
	color: transparent;
}

main {
  padding-top: 70px;
  min-height: 100%;
  height: 100%;
}

.odd-row {
  padding: 0.5rem;
  /* padding-left: 2rem; */
}

.even-row {
  background-color: #F5F5F5;
  padding: 0.5rem;
  /* padding-left: 2rem; */
}

.delete:hover {
  background-color: red;
}

.enterprise-box {
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem 0;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
}

.custom-scrollbars > div {
  overflow-x: hidden !important;
  padding-bottom: 1rem;
}


.enterprise-edit {
  padding-right: 1em;
  padding-left: 1em;
}

.enterprise-edit .row {
  padding-top: 1em;
  padding-bottom: 1em;
}

.enterprise-edit .row:nth-child(odd) {
  background-color: #F5F5F5;
}

.logo-container {
  position: relative;
  height: 100px;
  width: 100px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.small-white-button {
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  background-color: #fff;
  border: none;
  padding: 0.35rem;
  color: rgb(47, 47, 47);
  min-width: 6.5rem;
}

.small-white-button:hover {
  opacity: 0.7;
}

.small-white-button.selected {
  background-color: #B5E413;
  color: #FFF;
}

.small-white-button.lighter-text.selected {
  font-weight: 400;
}
.infoRows {
    padding: 1em;
}

.input-bar {
    border: solid #999 !important;
    border-width: 0px 0px 1px 0px !important;
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  .limited-input-bar{
    /* border: none !important; */
    margin-left: 1em;
    margin-top: 0.25em;
    padding: 0 0.5em !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }
  
  .input-bar:focus {
    border: solid #b5e413 !important;
    border-width: 0px 0px 1px 0px !important;
    box-shadow: none !important;
  }
.backgroundimage{
	width: 100%;
	height: 100% !important;
	position: inherit;
	background-image: url('/public/images/loginbg.jpg');
  	background-position: center;
  	background-size: cover;
		background-repeat: no-repeat;	
}
.logo{
	width: 100px;
	height: 100px;
	background-image: url('/public/images/qibbie_logo.png');
  	background-position: center;
  	background-size: contain;
  	background-repeat: no-repeat;
  	margin: auto;
  	margin-bottom: 25px;
}
.login-button {
  color: #ffffff;
  background-color: rgba(119, 119, 119, 1) !important;
  width: 100%;
}

.login-button:hover {
  background-color: rgb(80, 80, 80) !important;
}

#login-form {
  width: 400px;
}

/*GREYBOX FOR LOGIN AND REGISTER FORM*/
.greybox {
 box-shadow: 7px 10px 6px -4px rgba(74,74,74,0.66); 
   padding: 50px !important;
   background-color: rgba(132, 152, 173, 0.6);
 }

.password-forgotten {
  cursor: pointer;
  
}

@media only screen and (max-width: 768px) {
  .login-button {
    width: 100%;
  }
}

@media only screen and (max-width: 600px){
	.loginbox{
		height: 100% !important;
		/* padding: 50px 0px 50px 0px !important; */
	}
}
header {
  height: 70px;
  box-shadow: -1px -2px 9px 0px rgba(0,0,0,0.36);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}

.navbar {
  background-color: #ffffff;
  height: 70px;
}

.nav-link {
  margin-left: 1em;
  color: #777777;
}

.nav-link:hover {
  color: #000000;
}

.nav-link:focus{
  border: none;
  outline: 0;
}

.active-nav {
  font-weight: bold;
}

.header-container {
  width: 100%;
  box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
}

.qibbie-logo {
  background-image: url('/images/qibbie_logo_neg.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 64px;
  width: 70px;
  /* margin-bottom: 3px; */
  position: absolute;
  top: 3px;
}

@media (max-width: 768px) {
  header {
    height: 40px;
  }

  main {
    padding-top: 40px;
  }

  .qibbie-logo {
    height: 30px;
  }

  .navbar {
    padding: 0 !important;
  }
}

.spinner-loader,
.spinner-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner-loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.block-loader,
.block-loader:before,
.block-loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.block-loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.block-loader:before,
.block-loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.block-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.block-loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.codeinput-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.codeinput-row {
  width: 450px;
}

.code-cell {
  display: inline-block;
  margin: 5px;
  flex: 1 1 auto;
  width: 1%;
  font-size: xx-large;
  height: 2.3em;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .codeinput-row {
    width: 100%;
  }
}
