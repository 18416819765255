html, body, #root {
  min-height: 100%;
  height: 100%;
}

body {
  background-image: url('/images/background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Raleway', sans-serif !important;
  background-attachment: fixed;
}

.App {
  overflow: auto;
}