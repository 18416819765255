.infoRows {
    padding: 1em;
}

.input-bar {
    border: solid #999 !important;
    border-width: 0px 0px 1px 0px !important;
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }

  .limited-input-bar{
    /* border: none !important; */
    margin-left: 1em;
    margin-top: 0.25em;
    padding: 0 0.5em !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }
  
  .input-bar:focus {
    border: solid #b5e413 !important;
    border-width: 0px 0px 1px 0px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }