header {
  height: 70px;
  -webkit-box-shadow: -1px -2px 9px 0px rgba(0,0,0,0.36);
  -moz-box-shadow: -1px -2px 9px 0px rgba(0,0,0,0.36);
  box-shadow: -1px -2px 9px 0px rgba(0,0,0,0.36);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}

.navbar {
  background-color: #ffffff;
  height: 70px;
}

.nav-link {
  margin-left: 1em;
  color: #777777;
}

.nav-link:hover {
  color: #000000;
}

.nav-link:focus{
  border: none;
  outline: 0;
}

.active-nav {
  font-weight: bold;
}

.header-container {
  width: 100%;
  -webkit-box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
  -moz-box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
  box-shadow: -2px 5px 5px -1px rgba(0,0,0,0.12);
}

.qibbie-logo {
  background-image: url('/images/qibbie_logo_neg.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 64px;
  width: 70px;
  /* margin-bottom: 3px; */
  position: absolute;
  top: 3px;
}

@media (max-width: 768px) {
  header {
    height: 40px;
  }

  main {
    padding-top: 40px;
  }

  .qibbie-logo {
    height: 30px;
  }

  .navbar {
    padding: 0 !important;
  }
}
